import { template as template_1d6f6662cc7b4d668994242949f821bb } from "@ember/template-compiler";
const FKLabel = template_1d6f6662cc7b4d668994242949f821bb(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
