import { template as template_bd72ce3930154f77b4da2333679b0935 } from "@ember/template-compiler";
const SidebarSectionMessage = template_bd72ce3930154f77b4da2333679b0935(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
