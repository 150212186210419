import { template as template_2181d82cc7f14409a97e18808b2f6a6f } from "@ember/template-compiler";
const FKControlMenuContainer = template_2181d82cc7f14409a97e18808b2f6a6f(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
