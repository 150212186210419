import { template as template_b26bb0b586fa4207a04c923cf26651ff } from "@ember/template-compiler";
const FKText = template_b26bb0b586fa4207a04c923cf26651ff(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
